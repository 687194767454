// @ts-nocheck

export function isInViewport(element: any) {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
export async function compressImage(file: any, max_size_mb = 20, alwaysCompressImageSubmission = false) {
  const MAX_SIZE_MB = max_size_mb;
  const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
  const TIMEOUT_MS = 5000;

  return new Promise((resolve, reject) => {
    let compressedFile;
    let smallestFile = file;
    let canvas = document.getElementById('canvas');
    if (!canvas) {
        canvas = document.createElement('canvas');
        canvas.id = 'canvas';
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const compress = (quality) => {
          return new Promise((resolve) => {
            canvas.toBlob((blob) => {
              resolve(new File([blob], 'image.webp', { type: 'image/webp' }));
            }, 'image/webp', quality);
          });
        };

        const compressAggressively = async () => {
          let quality = 0.8;
          compressedFile = await compress(quality);
          smallestFile = compressedFile;
          while (compressedFile.size > MAX_SIZE_BYTES && quality > 0.1) {
            quality -= 0.1;
            canvas.width *= 0.9;
            canvas.height *= 0.9;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            compressedFile = await compress(quality);
            if (compressedFile.size < smallestFile.size) {
              smallestFile = compressedFile;
            }
          }
          return compressedFile;
        };

        const timeoutPromise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(smallestFile);
          }, TIMEOUT_MS);
        });

        Promise.race([compressAggressively(), timeoutPromise])
          .then((result) => {
            compressedFile = result as File;
            if (compressedFile.size <= MAX_SIZE_BYTES) {
              resolve(compressedFile);
            } else {
              resolve(smallestFile);
            }
          })
          .catch(reject);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}

export function getOrientationPermission(){
  // Check if the browser supports motion and orientation permissions
  if (typeof(DeviceOrientationEvent) !== 'undefined' && typeof(DeviceMotionEvent) !== 'undefined') {
    if (typeof(DeviceOrientationEvent.requestPermission) === 'function' && typeof(DeviceMotionEvent.requestPermission) === 'function') {
      // Request for motion and orientation permissions
      DeviceOrientationEvent.requestPermission()
        .then(permissionResponse => {
          if (permissionResponse === 'granted') {
            alert('Motion and orientation permissions granted.');
          } else {
            alert('Motion and orientation permissions denied.');
          }
        })
        .catch(error => {
          alert(error);
        });

      DeviceMotionEvent.requestPermission()
        .then(permissionResponse => {
          if (permissionResponse === 'granted') {
            alert('Motion and orientation permissions granted.');
          } else {
            alert('Motion and orientation permissions denied.');
          }
        })
        .catch(error => {
          alert(error);
        });
    } else {
      alert('This browser does not support motion and orientation permissions.');
    }
  } else {
    alert('This browser does not support motion and orientation events.');
  }
}

export function deleteCookie(name) {
  if (document.cookie.indexOf(name) != -1) {
    var d = new Date()
    d.setTime(d.getTime() - (365*24*60*60*1000))
    var expires = 'expires='+ d.toUTCString()
    document.cookie = `${name}=; ` + expires + '; path=/'
  }
}

export function distanceBetweenTwoGeoCoordinatesInMeters(lat1, lon1, lat2, lon2) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0
  }
  else {
    const radlat1 = Math.PI * lat1/180
    const radlat2 = Math.PI * lat2/180
    const theta = lon1-lon2
    const radtheta = Math.PI * theta/180
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 1.609344
    return dist*1000
  }
}

export function checkIfTeamPositionsInRadiusWithin10Mins(
  teamPositions, teamName, challengePosition) {
  if (teamPositions) {
    const tenMinutesAgoInSeconds = Math.floor(Date.now() / 1000) - 600
    return teamPositions.some(position => {
      if (position.teamName === teamName && position.updatedOn.seconds > tenMinutesAgoInSeconds) {
        const distance = distanceBetweenTwoGeoCoordinatesInMeters(
          challengePosition.lat, challengePosition.lon, position.lat, position.lon
        )
        return distance <= challengePosition.radius_in_meters
      }
    })
  }
  return false
}

// export async function compressImage(file: any, sizeLimit: any) {
//   // var fileInput = document.getElementById('fileInput')
//   // var file = fileInput.files[0]
//   var reader = new FileReader()
//   reader.onload = function (e) {
//     var img = new Image()
//     img.src = e.target.result
//     img.onload = function () {
//       console.log('img', img.size)
//       var canvas = document.createElement('canvas')
//       var ctx = canvas.getContext('2d')
//       ctx.drawImage(img, 0, 0)
//       var width = img.width
//       var height = img.height
//       canvas.width = width
//       canvas.height = height
//       ctx = canvas.getContext('2d')
//       ctx.drawImage(img, 0, 0, width, height)
//       var dataUrl = canvas.toDataURL('image/jpeg', 1)
//       var compression = 1
//       while (dataUrl.length > 1000000) {
//         console.log('dataUrl.length', dataUrl.length)
//         compression = compression - 0.05
//         dataUrl = canvas.toDataURL('image/jpeg', compression)
//       }
//       document.getElementById('compressedImage').src = dataUrl
//       document.getElementById('downloadLink').href = dataUrl
//     }
//   }
//   reader.readAsDataURL(file)
// }
